import authAxiosInstance from "../../sevices/authAxiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProducts = createAsyncThunk(
    "catalog/getProductsApi",
    async ({ locale, actionTypes = [], productLine = [] } , { rejectWithValue }) => {
        try {
            const params = {
                locale,
                ...(actionTypes.length && { actionType: actionTypes }),
                ...(productLine.length && { productLine: productLine })
            };

            const res = await authAxiosInstance({
                url: `catalogservice/catalog/products`,
                method: "GET",
                params: params
            });
            return res;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);