import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Button } from "../components";

const NotFoundContainer = styled('div')(({ theme: { breakpoints } }) => `
  margin: 100px 500px 250px 300px;
  ${[breakpoints.down('sm')]} {
    margin: 240px 50px 270px 50px;
  }
`);

const BackgroundContainer = styled('div')(
  () => `
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 50%;
    background-position-y: bottom;
    display: flex;
`);

const FallbackErrorPage = ({ resetErrorBoundary }) => {
    return (
        <BackgroundContainer>
            <NotFoundContainer>
                <Typography variant="h1" sx={{ fontFamily: "Aeonik Bold"}} data-testid="errorCode">Unexpected error</Typography>
                <Typography sx={{ fontFamily: "Aeonik Light"}} fontSize='24px'>Something went wrong on our end.</Typography>
                <Typography sx={{ fontFamily: "Aeonik Regular"}} fontSize='18px' paddingTop='20px'>Return to the homepage to continue.
				Please contact us at 1-866-MODERNA (1-866-663-3762) if the error persists.</Typography>
                <Button onClick={resetErrorBoundary} sx={{ textTransform: "none", marginTop: {xs: 7, sm: 3}}} buttonType='mds-primary'>Return Home</Button>
            </NotFoundContainer>
        </BackgroundContainer>
    )
}

export default FallbackErrorPage;
