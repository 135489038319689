import React, { useState, useEffect } from 'react';
import { Button, TextInput } from "..";
import { IdxStatus } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useHistory } from 'react-router-dom';
import { Link as MuiLink, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIdxTransaction, useUserInfoContext } from '../../contexts';
import { validateFields } from "../../utils/Helper";
import { fetchUserApi, getOrderCartApi } from "../../features/commerce/commerceThunkApi";
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { setLocale, setLocaleISOCode } from '../../features/globalStates/globalStatesSlice';
import { getBaseStoreDataApi } from "../../features/commerce/commerceThunkApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTracking } from '@modernatx/tracking';

const BannerSection = styled('div')(({ theme: { breakpoints } }) => `
  position: relative;
  width: 60%;
  margin: 100px 0 90px 80px;
  ${[breakpoints.down('md')]} {
    margin-left: 40px;
  }
`);

const Container = styled('div')(
  ({ aboveFoldBGImage, theme: { palette: { primary } }}) => `
  background-color: ${primary.main};
  display: flex;
  font-family: 'Aeonik Regular', 'Arial';
  background-image: url(${aboveFoldBGImage});
  background-size: 100% auto;
`
);

const SignUpLink = ({ locale, url, ...props }) => {
  return (
    locale === "en-DE" ? <MuiLink href={url} underline="none" {...props}>{ props?.children }</MuiLink> : <Link to={url} {...props}>{ props?.children }</Link>
  );
}
const OktaSignIn = ({ aboveFoldBGImage, content, setLoading, loading }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [showpassword, setShowpassword] = useState(false);
  const [processing, setProcessing] = useState(true);
  const { setTransaction } = useIdxTransaction();
  const { authState, oktaAuth } = useOktaAuth() || '';
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  const { setUserInfo } = useUserInfoContext() || '';
  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const invalidEmailFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_EMAIL_FORMAT");
  const invalidEmailFormatError = invalidEmailFormatErrorData && invalidEmailFormatErrorData[0]?.fields?.message;

  const invalidCredentialsErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_CREDENTIALS");
  const invalidCredentialsError = invalidCredentialsErrorData && invalidCredentialsErrorData[0]?.fields?.message;

  const accountLockedErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "ACCOUNT_LOCKED");
  const accountLockedError = accountLockedErrorData && accountLockedErrorData[0]?.fields?.message;

  const clientName = process.env.OKTA_CLIENT_NAME;
  const contextUrl = process.env.OKTA_CONTEXT_URL;
  const clientId = process.env.OKTA_CLIENT_ID;
  const gatingUrl = process.env.OKTA_GATING_URL;
  const { baseStoreData } = useSelector((store) => store?.commerce);
  const isOrderingEnabled = baseStoreData?.commerceEnabled;
  const redirectUrl = `${window.location.origin}/login/callback`;
  const gatingCreateUserUrl = `${gatingUrl}/${locale}/?clientId=${clientId}&clientName=${clientName}&contextUrl=${contextUrl}&redirectUrl=${redirectUrl}`;
  const registerPageUrl = locale === 'en-DE' ? gatingCreateUserUrl : `/${locale}/sign-up`;
  const inputSxStyles={ marginBottom: "10px", minHeight: '70px' };

  const {
    trackCustom,
    trackError
    } = useTracking();

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setInvalidPassword("");
  };

  const handleClickShowPassword = () => {
    setShowpassword(!showpassword);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setInvalidEmail("");
  };

  const validate = () => {
    let isValid = true;
    let emailError;
    let passwordError;
    if (!email) {
      isValid = false;
      emailError = "Please enter your email Address.";
    }

    if (!validateFields("email", email)) {
      isValid = false;
      emailError = invalidEmailFormatError;
    }

    if (!password) {
      isValid = false;
      passwordError = "Please enter your password.";
    }

    setInvalidEmail(emailError);
    setInvalidPassword(passwordError);

    return isValid;
  };

  useEffect(() => {
    setProcessing(false);
  }, [])

  const loadData = async () => {
    setProcessing(true);
    try {
      const userDetails = await oktaAuth.getUser()
      dispatch(fetchUserApi({ userId: userDetails?.sub }))
      .unwrap()
      .then(async (user) => {
        if (!user?.data) {
          localStorage.clear(); //for localStorage
          sessionStorage.clear(); //for sessionStorage
          localStorage.setItem("invalidUser", true);
          trackError(new Error("Invalid Okta user"));
          await oktaAuth.signOut();
        }
        localStorage.setItem("currentUser", JSON.stringify(user?.data))
        setUserInfo(user.data);
        if(user.data.country.isocode==="DE"){
          dispatch(setLocale('en-DE'));
          dispatch(setLocaleISOCode('DE'));
          dispatch(getBaseStoreDataApi({ baseSiteId: 'modernaDirect-DE' }))
        } else {
          dispatch(setLocale('en-US'));
          dispatch(setLocaleISOCode('US'));
          dispatch(getBaseStoreDataApi({ baseSiteId: 'modernaDirect-US' }))
          dispatch(getOrderCartApi({ userId: user?.data?.uid })).unwrap()
            .then((data) => {
              setProcessing(false);
            })
            .catch((error) => {
              setProcessing(false);
            })
          trackCustom("User Signed In",{"OktaID": user?.data?.uid})
          }
        if(window.location.pathname.includes('edit-reservations')) {
          history.push(`/${locale}/manage-reservations` + window.location.search)
        } else {
          history.push(window.location.pathname + window.location.search);
        }
        setProcessing(false);
      })
      .catch( async (error) => {
        localStorage.clear(); //for localStorage
        sessionStorage.clear(); //for sessionStorage
        localStorage.setItem("invalidUser", true);
        await oktaAuth.signOut();
        setInvalidPassword(error);
        setProcessing(false);
      })
      
    } catch (error) {
      localStorage.clear(); //for localStorage
      sessionStorage.clear(); //for sessionStorage
      localStorage.setItem("invalidUser", true);
      await oktaAuth.signOut();
      setInvalidPassword(error);
      setProcessing(false);
    }
  }

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      // when rendering the login form we check for an active session and if one exists, logout to prevent reuse
      if (localStorage.getItem("currentUser")) {
        localStorage.clear();
      }

      (async () => {
        try {
          let session = await oktaAuth.session.get();
          if (session?.status === "ACTIVE") {
            await oktaAuth.signOut();
          }
        } catch (e) {
        }
      })();
    }
  }, [authState]);

  const signIn = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        setProcessing(true);
        let newTransaction = await oktaAuth.idx.authenticate();
        setTransaction(newTransaction);
        if (newTransaction.status === IdxStatus.SUCCESS) {
          setProcessing(false);
          oktaAuth.tokenManager.setTokens(newTransaction.tokens);
          if (!localStorage.getItem("currentUser") || localStorage.getItem("currentUser") === "undefined")
            await loadData();
        } else {
          const newTransaction = await oktaAuth.idx.proceed({ username: email, password: password });
          const { status, tokens, messages } = newTransaction;
          if (status === IdxStatus.SUCCESS) {
            setProcessing(false);
            oktaAuth.tokenManager.setTokens(tokens);
            if (!localStorage.getItem("currentUser") || localStorage.getItem("currentUser") === "undefined")
              loadData();
          } else if (status === IdxStatus.PENDING && messages) {
            setProcessing(false);
            sessionStorage.removeItem("okta-transaction-storage");
            if (messages[0]?.i18n?.key === "errors.E0000004") {
              setInvalidPassword(invalidCredentialsError);
            }
            else if(messages[0]?.i18n?.key === "errors.E0000119"){
              setInvalidPassword(accountLockedError);
            }
            else
              setInvalidPassword(messages[0].message);
          } else if (newTransaction.status === IdxStatus.PENDING && !newTransaction.status.tokens){
              setTimeout(() => {
                window.location.href = newTransaction?.nextStep?.href;
              }, 100);
          } else if (status === IdxStatus.TERMINAL) {
            setProcessing(false);
            sessionStorage.removeItem("okta-transaction-storage");
            setInvalidPassword(messages[0].message);
          } else {
            setProcessing(false);
            sessionStorage.removeItem("okta-transaction-storage");
            setInvalidPassword(invalidCredentialsError);
          }
        }
      }
    }
    catch (err) {
      setProcessing(false);
      setInvalidPassword(err);
    }
  }

  return (
    <form className="login-form " data-form-name="not brand aligned|hcp|log-in form" data-form-type="login-form|non-dynamic form">
      <Container>
        {desktop && <BannerSection>
          <Box 
            component={'img'}
            src={isOrderingEnabled ? content?.fields?.image?.fields?.file?.url : content?.fields?.imagePrebook?.fields?.file?.url}
            alt="Sign In Card Background Image"
            height="100%"
            width="100%"
          ></Box>
        </BannerSection>}
        <Box component={"section"} display='flex' flexDirection= 'column' borderRadius={{xs:'16px', sm: '5px'}} backgroundColor='neutral.white' width={{xs:'100%',sm:'40%'}} zIndex={'100'} p={{xs:'20px', sm:'20px', md:'40px', lg:'50px 80px'}} m={{xs:'80px 20px 88px 20px', sm:'95px 85px 90px -30px'}}>
          <Typography data-testid={'signInHeadline'} variant={'h2'} fontFamily='Aeonik Light' color='primary.main' mt={'15px'} fontSize={{xs:'30px', sm:'20px', md:'27px', lg:'34px'}} mb={{xs:'12px', sm:'10px', md:'20px', lg:'35px'}} id="logInTitle">{isOrderingEnabled ? content?.fields?.headline : content?.fields?.headlinePrebook}</Typography>
          { mobile && content?.fields?.subheader && <Typography data-testid={'signInSubheader'} variant={'p2'} component={'p'} color='primary.main' mb='15px'>{documentToReactComponents(isOrderingEnabled ? content?.fields?.subheader : content?.fields?.subheaderPrebook)}</Typography>}
          <TextInput
            data-testid="email"
            id="email"
            label="EMAIL"
            placeholder="Enter Email"
            inputType="email"
            variant="standard"
            focused
            value={email}
            sx={inputSxStyles}
            helperText={invalidEmail}
            error={!!invalidEmail}
            onChange={handleEmail}
          />
          <TextInput
            data-testid="password"
            error={!!invalidPassword}
            value={password}
            inputType={showpassword ? 'text' : 'password'}
            id="password"
            placeholder="Enter Password"
            label="PASSWORD"
            focused
            helperText={invalidPassword}
            variant="standard"
            sx={inputSxStyles}
            showpassword={showpassword}
            handleClickShowPassword={handleClickShowPassword}
            onChange={handlePassword}
            fieldType="password"
          />
          <Link id="forgotPassword" data-testid="forgotPasswordLink" to={`/${locale}/request-reset-password`}><Typography variant={'link'} component={'div'} color={'secondary.textLinks'}>Reset your password</Typography></Link>
          <Box display={'flex'} mb={'20px'} mt={{xs:'60px', sm:'20px', md:'50px', lg:'90px'}}>
            <Button
              data-testid="loginBtn"
              id="logInButton"
              type="submit"
              buttonType="primary"
              sx={{ marginRight: "24px", '@media screen and (max-width: 768px)': { marginRight: "0px" } }}
              onClick={signIn}
              loading={processing}
            >{content?.fields?.loginButtonLabel}
            </Button>
            {/* <Button buttonType="secondary">Create Account</Button> */}
          </Box>
          <Typography variant={'p2'} mb={'20px'} id="createNewAccount">{content?.fields?.registerMessage}
            <SignUpLink data-testid="signupLink" locale={locale} url={registerPageUrl} style={{marginLeft: "5px"}}>
              <Typography variant={'link'} color={'secondary.textLinks'} >{content?.fields?.registerLink?.fields.label}</Typography>
            </SignUpLink>
          </Typography>
        </Box>
      </Container>
    </form>
  );
};
export default OktaSignIn;
