import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { TypographyH2 } from "../../assets/fonts/typographyTheme";
import { CustomRadio } from "../CustomComponents/CustomRadio";
import { PAYMENT_OPTIONS, PAYMENT_METHOD_STATUS } from "../../constants";
import PaymentMethodSelection from "./PaymentMethodSelection";
import { setSelectedPaymentMethod } from "../../features/commerce/commerceSlice";
import { listPaymentMethodsApi } from "../../utils/PaymentsService/PaymentMethodApis";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { checkIsExpired } from "./Helper";
import { styled } from '@mui/material';

export const paymentMessage = {
    payNow:
        <Typography>
            <span style={{ fontWeight: 'bold' }}>Pay now:</span> Your Credit Card payment or ACH Bank Transfer will be processed when your order ships.
        </Typography>,
    payLater:
        <Typography>
            <span style={{ fontWeight: 'bold' }}>Pay later:</span> An invoice with payment instructions will be sent to the billing address selected for this order.
        </Typography>
}

export const PayLater = () => {
    const locale = useSelector((state) => state.globalStates.locale);
    return (
            <Box gap="16px" data-testid={'payment-method'} mb={4}>
                <Typography fontFamily={"Aeonik Regular"}>
                    An invoice with <Link to={`/${locale}/invoicepay`} target="_blank">payment instructions</Link> will be sent to the billing address selected for this order.
                </Typography>
            </Box>
    );
}

export const PaymentOptions = ({ paymentOption, handlePaymentSelection, handleErrorMsg, paymentMethodsList, setProcessing, setPaymentMethodsList, setIsSnackbarOpen, setSnackbarMessage }) => {
    const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
    const dispatch = useDispatch();

    const handlePaymentMethodSelection = async (paymentMethodId) => {
        try {
            const response = await listPaymentMethodsApi({ status: PAYMENT_METHOD_STATUS.ACTIVE });
            if (response.status === 200) {
                let allPaymentMethods = response?.data?.paymentMethodsList;
                let selectedPM = allPaymentMethods?.find(pm => pm.paymentMethodId === paymentMethodId);
                let isCardExpired = checkIsExpired(selectedPM.paymentMethodInfo.expirationDate);
                if (selectedPM && !isCardExpired) {
                    setPaymentMethodAdded(true);
                    dispatch(setSelectedPaymentMethod(selectedPM));
                } else if (isCardExpired) {
                    setPaymentMethodAdded(false);
                    handleErrorMsg("This payment method has expired. Please update your payment details and try again.");
                }
            }
        } catch (error) {
            let errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            setPaymentMethodAdded(false);
            handleErrorMsg(errorMessage);
        };
    }

    const VerticalStyledBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '24px',
        marginBottom: '32px'
      });

    return(
        <VerticalStyledBox>
            <CustomRadio
                id={PAYMENT_OPTIONS?.PAY_NOW}
                checked={paymentOption === PAYMENT_OPTIONS?.PAY_NOW}
                label={paymentMessage?.payNow}
                onChange={handlePaymentSelection}
                value={PAYMENT_OPTIONS?.PAY_NOW}
                size="medium"
            />
            {paymentOption === PAYMENT_OPTIONS?.PAY_NOW && <Box style={{margin: '12px 24px'}}>
                <PaymentMethodSelection
                    handleErrorMsg={handleErrorMsg}
                    paymentMethodsList={paymentMethodsList}
                    setPaymentMethodAdded={setPaymentMethodAdded}
                    paymentMethodAdded={paymentMethodAdded}
                    handlePaymentMethodSelection={handlePaymentMethodSelection}
                    setProcessing={setProcessing}
                    setPaymentMethodsList={setPaymentMethodsList}
                    setIsSnackbarOpen={setIsSnackbarOpen}
                    setSnackbarMessage={setSnackbarMessage}
                />
            </Box>}
            <CustomRadio
                id={PAYMENT_OPTIONS?.PAY_LATER}
                checked={paymentOption === PAYMENT_OPTIONS?.PAY_LATER}
                label={paymentMessage?.payLater}
                onChange={handlePaymentSelection}
                value={PAYMENT_OPTIONS?.PAY_LATER}
                size="medium"
                sx={{marginTop: "10px"}}
            />
        </VerticalStyledBox>
    )
}

