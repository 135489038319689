import { createAsyncThunk } from "@reduxjs/toolkit";
import commerceAuthAxiosInstance from "../../sevices/commerceAuthAxiosInstance";
import commerceNoAuthAxiosInstance from "../../sevices/commerceNoAuthAxiosInstance";
import { sortArrayByField, sortProductsByPriority } from "../../utils/Helper";
import { ORDERED_PRODUCT_BY_ID_OR_CODE } from "../../utils/Reservation/reservationCommons";

const orderableProductIds = process.env.ORDERING_PRODUCTS?.split(",");
const isSapEnabled = process.env.IS_SAP_ENABLED === "true";

export const fetchOrders = createAsyncThunk(
    "commerce/fetchOrders",
    async ({ userId, statuses }, {rejectWithValue} ) => {
        if (!isSapEnabled) { return rejectWithValue({message: "Deprecated API"});}

        try {
            const res = await commerceAuthAxiosInstance({
                url: `users/${userId}/orders`,
                method: "GET",
                params: {
                    pageSize: 1000,
                    userId: userId,
                    statuses: statuses
                }
            });
            return res?.data?.orders;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

//ADD TO CART for PDP page only (current cart)
export const addToOrderCartApi = createAsyncThunk(
    "commerce/addToOrderCart",
    async ({ userId, cartData }, {rejectWithValue} ) => {
        try {
            const res = await commerceAuthAxiosInstance({
                url: `users/${userId}/carts/current/entries`,
                method: "POST",
                data: JSON.stringify(cartData),
                params: {
                    userId: userId
                }
            });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

//REMOVE FROM CART for Ordering flow only (current cart)
export const removeFromOrderCartApi = createAsyncThunk(
    "commerce/removeFromOrderCart",
    async ({ userId, entryNumber }, {rejectWithValue} ) => {
        try {
            const res = await commerceAuthAxiosInstance({
                url: `users/${userId}/carts/current/entries/${entryNumber}`,
                method: "DELETE",
                params: {
                    userId: userId
                }
            });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

//Update order cart api
export const updateOrderCartApi = createAsyncThunk(
    "commerce/updateOrderCar",
    async ({ userId, entryNumber, updatedData}, {rejectWithValue} ) => {
        try {
            const res = await commerceAuthAxiosInstance({
                url: `users/${userId}/carts/current/entries/${entryNumber}`,
                method: "PATCH",
                data: JSON.stringify(updatedData),
                params: {
                    userId: userId
                }
            });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

//function to FETCH ORDER CART data
export const getOrderCartApi = createAsyncThunk(
    "commerce/orderCart",
    async ({ userId }, {rejectWithValue} ) => {
        try {
            const res = await commerceAuthAxiosInstance({
                url: `users/${userId}/carts/current?fields=DEFAULT`,
                method: "GET",
                params: {
                    userId: userId
                }
            });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
// one function for fetch all basestore data
export const getBaseStoreDataApi = createAsyncThunk(
    "commerce/baseStoreData",
    async ({ baseSiteId = "modernaDirect-US"}, {rejectWithValue} ) => {
        if (!isSapEnabled) { return rejectWithValue({message: "Deprecated API"});}
        try {
            const res = await commerceNoAuthAxiosInstance({
                url: `basestores/${baseSiteId}?fields=DEFAULT`,
                method: "GET",
            });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const getProductsApi = createAsyncThunk(
    "commerce/getProducts",
    async ({ catalogVersion = "modernaProductCatalog", userId,isAuthenticationRequired = true, excludedProductCodes = []}, {rejectWithValue} ) => {
        try {
            const axiosInstance = isAuthenticationRequired ? commerceAuthAxiosInstance : commerceNoAuthAxiosInstance;
            const res = await axiosInstance({
                url: `catalogs/${catalogVersion}/Online/categories/covid19Boosters?fields=DEFAULT`,
                method: "GET",
                params: {
                    userId: userId || null
                }
            });
            const products = res?.data?.products?.filter(product => !product.isReservable);
            res.data.products= products;
            // ECOM-36: Filter out excluded product codes.
            // This is a quick fix to hide 70900 without removing it from the backend
            if (Array.isArray(products) && Array.isArray(excludedProductCodes) && excludedProductCodes.length > 0) {
                res.data.products = products?.filter((product) => {
                    return excludedProductCodes.every((excludedProductCode) => {
                        return `${product.code}` !== `${excludedProductCode}`;
                    });
                });
            }
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const getOrderableProductsApi = createAsyncThunk(
  "commerce/getOrderableProducts",
  async ({ catalogVersion = "modernaProductCatalog" }, {rejectWithValue} ) => {
      try {
          const res = await commerceNoAuthAxiosInstance({
              url: `catalogs/${catalogVersion}/Online/categories/categories`,
              method: "GET",
              params: {
                  fields: 'DEFAULT'
              }
          });
          const products = res?.data?.products?.filter(product => orderableProductIds?.includes(product.code));

          // Sort the products
          return sortProductsByPriority(products, 'code');

      } catch (error) {
          if (!error.response) {
              throw error
          }
          // We got validation errors, let's return those so we can reference in our component and set form errors
          return rejectWithValue(error.response.data)
      }
  }
)

// This is the same API, with additional filtering added here to create a different store in Redux
export const getReservationProductsApi = createAsyncThunk(
    "commerce/getReservationProducts",
    async ({ catalogVersion = "modernaProductCatalog" }, {rejectWithValue} ) => {
        try {
            const res = await commerceNoAuthAxiosInstance({
                url: `catalogs/${catalogVersion}/Online`,
                method: "GET",
                params: {
                    fields: 'DEFAULT'
                }
            });
            const modernaProductsCategory = res?.data?.categories.find(category => category.name === "Moderna Products");
            // Add this once backend change is available
            const reservableProducts = modernaProductsCategory?.products.filter(product => product.isReservable);
            return sortArrayByField(reservableProducts, ORDERED_PRODUCT_BY_ID_OR_CODE, 'code');
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const fetchUserApi = createAsyncThunk(
    "commerce/fetchUser",
    async ({ userId }, {rejectWithValue} ) => {
        if (!isSapEnabled) { return rejectWithValue({message: "Deprecated API"});}
        try {
            const res = await commerceAuthAxiosInstance({
                url: `/modernaOrgUsers/${userId}/?fields=DEFAULT`,
                method: "GET",
                params: {
                    userId: userId
                }
            });
            return res;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response)
        }
    }
)

export const getProductDetailsApi = createAsyncThunk(
    "commerce/getProductDetails",
    async ({ productId }, {rejectWithValue}) => {
        try {
            const promises = [
                commerceAuthAxiosInstance({
                    url: `products/${productId}?fields=DEFAULT`,
                    method: "GET"
                }),
                commerceAuthAxiosInstance({
                    url: `products/${productId}/references`,
                    method: "GET"
                })
            ];
            const [res1, res2] = await Promise.all(promises);
            const orderPDPcontent = {
                productData: res1?.data,
                relatedProduct: res2?.data
            };
            return orderPDPcontent;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAddressesApi = createAsyncThunk(
    "commerce/getAddresses",
    async ({ userId }, {rejectWithValue} ) => {
        try {
            const res = await commerceAuthAxiosInstance({
                url: `/${userId}/addresses?fields=DEFAULT`,
                method: "GET",
                params: {
                    userId: userId
                }
            });
            return res;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const placeOrderAPI = createAsyncThunk(
    "commerce/placeOrder",
    async ({ userId, orderData }, {rejectWithValue} ) => {
        try {
            const res = await commerceAuthAxiosInstance({
                url: `users/${userId}/orders`,
                method: "POST",
                data: JSON.stringify(orderData),
                params: {
                    userId: userId,
                    cartId: "current"
                }
            });
            return res?.data;
        } catch (error) {
            //ECOM-267 temporary fix for missing delivery location, BE throwing 500 error so that we can capture in Dynatrace and trigger PagerDuty
            if (error.response?.data?.type && error.response?.data?.type === "OrderMissingDeliveryLocationException"){
                return error.response?.data?.data;
            }

            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const getReservationProductDetailsApi = createAsyncThunk(
    "commerce/getProductDetails",
    async ({ productId }, {rejectWithValue}) => {
        try {
            const promises = [
                commerceNoAuthAxiosInstance({
                    url: `products/${productId}`,
                    method: "GET",
                    params: {
                        fields: 'DEFAULT'
                    }
                }),
                commerceNoAuthAxiosInstance({
                    url: `products/${productId}/references`,
                    method: "GET"
                })
            ];
            const [res1, res2] = await Promise.all(promises);
            const orderPDPcontent = {
                productData: res1?.data,
                relatedProduct: res2?.data
            };
            return orderPDPcontent;
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
