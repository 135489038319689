import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Avatar from "@mui/material/Avatar";
import usaFlag from '../../assets/images/usa-flag.svg';
import switzerlandFlag from '../../assets/images/switzerland-flag.svg';
import { setLocale, setLocaleISOCode } from '../../features/globalStates/globalStatesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadHeaderStaticContent } from '../../features/contentful/contentfulThunkApi';
import { getBaseStoreDataApi } from '../../features/commerce/commerceThunkApi';

const isSapEnabled = process.env.IS_SAP_ENABLED === "true";

const Menu = styled('ul')(
  ({ show, theme: { palette: { primary, neutral, secondary }, breakpoints }}) => `
  position: absolute;
  top: 61px;
  right: 0px;
  background-color: ${secondary.main};
  border-top: 3px solid ${primary.blue};
  border-radius: 0px 0px 16px 16px;
  width: 190px;
  display: ${show ? "block" : "none"};
  box-shadow: 0px 3px 16px #00000029;
  padding: 0 15px;
  z-index: 10000000;
  ${[breakpoints.down('sm')]} {
    background-color: ${neutral.white};
    border-top:0px;
    top:80px;
    position:fixed;
    padding-bottom:80px;
    left:0px;
    width:100vw;
    height:100vh;
    display: ${show ? "flex" : "none"};
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:space-between;
  } 
`)

export const LocaleSelector = ({ baseStoreData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);

  const toggleMenu = () => setShowMenu(prev => !prev)
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showMenu])

  const handleOnClick = () => {
    if (isSapEnabled) {
      dispatch(getBaseStoreDataApi({ baseSiteId: 'modernaDirect-US' }));
    }
    dispatch(setLocale('en-US')); 
    dispatch(setLocaleISOCode('US'));
    history.push('/');
    dispatch(loadHeaderStaticContent({ title: 'Header - US'}));
    setShowMenu(false)
  }

  return (
    <Box position={'relative'}>
      <IconButton
        disableRipple
        id="localeSelector"
        aria-label="Locale Selector"
        onClick={toggleMenu}
        sx={{ padding: 0, color:"primary.blue" }}>
        <Avatar sx={{backgroundColor: 'neutral.white', color: 'secondary.main', border: '1px solid', borderColor:'primary.main', height:"32px", width:"32px" }}>
            <Box component={'img'} sx={{objectFit: 'cover'}} alt='usa' height="32px" width="32px" src={usaFlag}/>
          </Avatar>
      </IconButton>
      <Menu show={showMenu}>
        <Box display='grid' justifyContent='center' alignitems='center' my='20px'>
          {locale === 'en-US' || locale === '' ?
          <a href="https://ModernaDirect.ch" rel="noopener noreferrer">
            <IconButton
                disableRipple
                id="Switzerland"
                aria-label="Switzerland"
                sx={{ padding: 0, color:"primary.blue", gap:'12px' }}
            >
                <Avatar sx={{backgroundColor: 'neutral.white', color: 'secondary.main', border: '1px solid', borderColor:'primary.main', height:"32px", width:"32px"  }}>
                    <Box component={'img'} sx={{objectFit: 'cover'}} alt='switzerland' height="32px" width="32px" src={switzerlandFlag}/>
                </Avatar>
                <Typography variant={'p1'} color={'primary.main'}>Switzerland</Typography>
            </IconButton>
        </a>
           :
          <IconButton
            disableRipple
            id="USA"
            aria-label="USA"
            onClick={handleOnClick}
            sx={{ padding: 0, color:"primary.blue", gap:'12px' }}>
            <Avatar sx={{backgroundColor: 'neutral.white', color: 'secondary.main', border: '1px solid', borderColor:'primary.main', height:"32px", width:"32px"  }}>
              <Box component={'img'} sx={{objectFit: 'cover'}} alt='usa' height="32px" width="32px" src={usaFlag}/>
            </Avatar>
            <Typography variant={'p1'} color={'primary.main'}>USA</Typography>
          </IconButton>}
        </Box>
      </Menu>
    </Box>
  );
};
