import React, {useState,useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Snackbar, SnackbarContent } from "@mui/material";
import { OrderSummaryCard } from '../components/Ordering/OrderSummaryCard';
import OrderSummaryTable from '../components/Ordering/OrderSummaryTable';
import { AlertMessage, Spinner } from '../components';
import { mapProductNames } from '../utils/HelperFunctions/OrderAndReservationHelper';
import { getCheckoutCart } from '../utils/OrderService/CartApi';
import { PAYMENT_METHOD_STATUS, PAYMENT_OPTIONS } from "../constants";
import { listPaymentMethodsApi } from '../utils/PaymentsService/PaymentMethodApis';
import { setSelectedPaymentMethod } from '../features/commerce/commerceSlice';
import { getErrorMessage } from '../utils/getErrorMessage';
import { OrderNotes } from "../components/Payments/OrderNotes";
import { getSnackbarMessage, snackbarStyles } from '../components/Payments/Helper';
import GlobalBanner from '../components/GlobalBanner/GlobalBanner';
import { getOrderableProducts } from "../utils/Helper";

const OrderCheckoutPage = () => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const [processing, setProcessing] = useState(true);

  const userData = useSelector((store) => store?.commerce?.userData);
  const userId = userData?.userId;
  const cartId = useSelector((store) => store?.commerce?.cart?.cart?.cartId) || '';
  const orderableProducts = getOrderableProducts();
  const [summaryData, setSummaryData] = useState({});
  const [orderCartEntries, setOrderCartEntries] = useState([]);
  const uniqueAddressIds = new Set(orderCartEntries.map(entry => entry.shipToAddress?.addressId));
  const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTIONS?.PAY_NOW);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const isPaymentsEnabled = process.env.IS_PAYMENTS_ENABLED === 'true';
  const [isTaxPending, setIsTaxPending] = useState(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const getSummaryData = (cart) => {
    if (!cart) return {};
    let {
        subtotalListPrice,
        subtotalContractPrice,
        totalTax,
        totalListPrice,
        totalContractPrice,
        discountTotal ,
        promptPayEligible,
        promptPayTerms,
        totalContractPriceWithPromptPay
    } = cart?.cartPriceTotals?.total || {};
    return {
      totalDoses: cart?.totalQuantity || 0,
      listPrice: subtotalListPrice?.displayValue, 
      savings: discountTotal?.displayValue, 
      contractPrice: subtotalContractPrice?.displayValue, 
      taxes: totalTax?.displayValue,
      totalPrice: totalContractPrice?.displayValue || totalListPrice?.displayValue,
      isTaxPending: (!cart?.customerRecordHasSapValues && !cart?.isTaxExemptSelectedByCustomer) ?? true,
      promptPayEligible: promptPayEligible,
      promptPayTerms: promptPayTerms,
      totalContractPriceWithPromptPay: totalContractPriceWithPromptPay?.displayValue,
      contractInfo: cart?.contractInfo,
    }
  }

  useEffect(() => {
    setProcessing(true);

    dispatch(getCheckoutCart({ cartId: cartId, userId: userData?.userId }))
      .unwrap()
      .then(async (data) => {
        const { customerRecordHasSapValues, isTaxExemptSelectedByCustomer } = data?.cart || {};

        setIsTaxPending((!customerRecordHasSapValues && !isTaxExemptSelectedByCustomer) ?? true);
        setOrderCartEntries(data?.cart?.cartLineItems || []);
        setSummaryData(getSummaryData(data?.cart || null));
        if (isPaymentsEnabled) {
          await listPaymentMethodsApi({ status: PAYMENT_METHOD_STATUS.ACTIVE }).then((result) => {
            if (result.status === 200) {
              let defaultPaymentMethod = result?.data?.paymentMethodsList?.filter(paymentMethod => paymentMethod.isDefault === true)[0];
              dispatch(setSelectedPaymentMethod(defaultPaymentMethod));
              setPaymentMethodsList(result?.data?.paymentMethodsList);
            }
          }).catch((error) => {
            const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            setErrorMsg(errorMessage);
            alertRef.current?.openAlert(error);
          })
        }
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, []);

  const createDisplayEntry = useMemo(() => {
    return orderCartEntries.map((orderEntry => {
      if (!orderEntry) return null;
      const { productId, shipToAddress, billToAddress, purchaseOrderNumber } = orderEntry || {};
      const productMap = mapProductNames(orderableProducts);
      const productName = productMap[productId];
      return {
          id: orderEntry?.cartLineItemId,
          doses: parseInt(orderEntry?.quantity, 10) || 0,
          productId,
          productName: productName,
          listPrice: orderEntry?.unitListPrice?.displayValue,
          contractPrice: orderEntry?.unitContractPrice?.displayValue,
          priceTotal: orderEntry?.priceTotal,
          shipToAddress,
          billToAddress,
          purchaseOrderNumber
      };
    })).filter(entry => entry !== null);
    }, [orderCartEntries, orderableProducts]);


  const getEntriesForThisAddress = (addressId) => {
      let filteredEntries = createDisplayEntry?.filter(({shipToAddress}) => String(shipToAddress?.addressId) === String(addressId));
    return { items: filteredEntries,
            shipToAddress: filteredEntries[0]?.shipToAddress,
            billToAddress: filteredEntries[0]?.billToAddress,
            purchaseOrderNumber: filteredEntries[0]?.purchaseOrderNumber
        };
  }

  const errorMsgHandler = (errorMsg) => {
    let message = errorMsg || generalApiError;
    setErrorMsg(message);
    alertRef.current?.openAlert(message);
  }

  return (
    <>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={isSnackbarOpen}
        onClose={() => {setIsSnackbarOpen(false)}}
      >
        <SnackbarContent
          message={getSnackbarMessage(snackbarMessage)}
          sx={snackbarStyles}
        />
      </Snackbar>
      <AlertMessage
        variant={"filled"}
        type={"error"}
        message={errorMsg}
        sx={{ top: 120 }}
        ref={alertRef}
      />
      <Spinner processing={processing} />

      {!processing && orderCartEntries.length > 0 && <Grid
        data-testid="mainContainer"
        container
        padding={{ xs: 3, md:4, lg: "21px 72px"}}
      >
        <Grid item xs={12} paddingBottom={"21px"}>
          <GlobalBanner />
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: 500,
              lineHeight: "36px",
              fontFamily: "Aeonik Regular",
            }}
          >
            Place your order
          </Typography>
        </Grid>
        <Grid data-testid={"order-summary-table"} item xs={12} sm={8} paddingBottom={"32px"} >
          {[...uniqueAddressIds].map((addressId, index) => {
                  let entriesToRender = getEntriesForThisAddress(addressId);
                  let marginTop = index > 0 ? "33px" : "0px";
                  return <OrderSummaryTable key={index} orderDetail={entriesToRender} isTaxPending={isTaxPending} sx={{'#boxContainer': { marginTop: marginTop}}}/>
          })}
        </Grid>
        <Grid data-testid={"order-summary-card"} item xs={12} sm={4} overflow="unset" minWidth={"350px"} maxWidth={"486px"} paddingLeft={{md: 0, lg:"21px"}}>
          <OrderSummaryCard
            summaryData={summaryData}
            userId={userId}
            handleErrorMsg={errorMsgHandler}
            setProcessing={setProcessing}
            setPaymentOption={setPaymentOption}
            paymentOption={paymentOption}
            paymentMethodsList={paymentMethodsList}
            setPaymentMethodsList={setPaymentMethodsList}
            setIsSnackbarOpen={setIsSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
          />
          <OrderNotes paymentOption={paymentOption} summaryData={summaryData}/>
        </Grid>
      </Grid>}
    </>
  );
}

export default OrderCheckoutPage;
