import { Box, Typography } from "@mui/material";
import { Button } from "../Button";
import { useOktaAuth } from '@okta/okta-react';

const LogoutFooter = () => {
    const { oktaAuth } = useOktaAuth() || '';
    
    const logOut = async () => {
        try {
            localStorage.clear();
            sessionStorage.clear();
    
            await oktaAuth.signOut();
    
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
            <Box padding='24px 0px' display='flex' flexDirection='column' alignItems='flex-start'>
                <Typography color='#85888E' data-testid='copyrightMesssage'>© 2024 Moderna, Inc.</Typography>
                <Button buttonType="link" onClick={logOut} sx={{textTransform: "none"}} data-testid='logoutBtn'>Log out</Button>
            </Box>
    )
}

export default LogoutFooter;
