import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react'
import {Box, Typography} from "@mui/material";
import { Button } from '../Button';
import { loadProductDetailContentComponent } from '../../features/contentful/contentfulThunkApi';
import { ModalComponent } from '../ModalComponent';
import DocumentIcon from '../../assets/images/pdfDocIcon.svg';
import ImageGallery from '../ImageGallery/ImageGallery';
import { sortProductImages } from '../../utils/Helper'
const ProductDetailModal = ({ closeModal, openModal }) => {
  const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';
  const dispatch = useDispatch();
  const { productData } = useSelector((store) => store?.commerce?.productDetails);
  const singleProductInfo = useSelector((store) => store?.commerce?.singleProductInfo);
  const productDetails = isCatalogServiceEnabled ? singleProductInfo : productData;
  const { productDetailContentComponent: staticContent } = useSelector((store) => store?.contentful);
  const factSheetLinks = staticContent?.factSheetLinks?.filter(factSheetLink => {
    return factSheetLink?.fields?.productCode === (isCatalogServiceEnabled? productDetails?.materialMasterId : productDetails?.code)
  });

  const filterImages = () => {
      if (isCatalogServiceEnabled) {
          return sortProductImages(productDetails?.displayInfo?.images)
      }
      else {
          const filterImage = productDetails?.images?.filter(image => {
              return image?.format === "galleryProduct" && image?.imageType === "GALLERY";
          });
          return filterImage;
      }
  }

  const images = filterImages()?.map((image, i) => {
    return ({
      original: image?.url,
      thumbnail: image?.url,
      originalAlt: `Original Product ${i+1}`,
      thumbnailAlt: `Thumbnail Product ${i+1}`
    })
  });

  useEffect(() => {
    if(staticContent && Object.keys(staticContent).length === 0) {
      dispatch(loadProductDetailContentComponent());
    }
  },[dispatch, staticContent]);

  const ageGroup = () => {
      return isCatalogServiceEnabled? productDetails?.displayInfo?.displayAge : productDetails?.ageGroup
  }

  const productDescription = () => {
      const productDescription = `${productDetails?.description} ${productDetails?.doseVolume} ${productDetails?.doseVolumeUOM}`
      return isCatalogServiceEnabled? productDetails?.displayInfo?.description : productDescription
  }

  const ProductDetail = (
    <Box display="flex" width="545px" gap="24px" flexShrink={0} flexWrap="wrap" alignContent="center" alignItems="center">
      <Typography display="flex" width="545px" paddingBottom="8px" fontFamily="Aeonik Regular" fontSize="24px" fontWeight="700">Product information</Typography>
        <ImageGallery images={images} />
      <Box width="520px" height="auto" display="flex" flexDirection="column" flexShrink={0}>
        <Box display="flex" gap="24px">
          {ageGroup() && <Typography textAlign="left" fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">{ageGroup()}</Typography>}
          <Typography textAlign="left" fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">NDC#{productDetails?.ndc}</Typography>
        </Box>
        <Typography textAlign="left" fontFamily="Aeonik Regular" fontSize="24px" fontWeight="300">
            {isCatalogServiceEnabled? productDetails?.displayInfo?.name : productDetails?.name}
        </Typography>
        <Typography textAlign="left" fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">
            {productDescription()}
        </Typography>
      </Box>
      {factSheetLinks?.length > 0 && 
        <Box 
          data-testid="downloadSection"
          display="flex"
          width="545px"
          alignItems="flex-start"
          alignContent="flex-start"
          gap="16px"
          flexShrink={0}
          flexWrap="wrap"
        >
          <Typography textAlign="left" width="539px" fontFamily="Aeonik Regular" fontSize="18px" fontWeight="700">Downloads</Typography>
          {factSheetLinks
            .map((factSheetLink, i) => {
              return (
                <Link key={i} to={{ pathname: factSheetLink?.fields?.url }} target="_blank">
                  <Button leftIcon={DocumentIcon} buttonType='mds-outline' sx={{textTransform: "none", padding: "12px 16px"}}>{factSheetLink?.fields?.label}</Button>
                </Link>
              )
            })
          }
        </Box>
      }
      <Box display="flex" width="545px" padding="12px 90px" justifyContent="center">
        <Button buttonType='mds-primary' sx={{textTransform: "none", padding: "16px 32px"}} onClick={closeModal}>Close</Button>
      </Box>
    </Box>
  )

  return (
    <ModalComponent 
      openModal={openModal}
      closeModalHandler={closeModal}
      style={{ 
        maxWidth: '598px',
        width: 'auto',
        padding: '24px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
        overflowX: 'hidden',
    }}
    modalContent={ProductDetail}
    />
  )
}

export default ProductDetailModal