import { useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetailsApi } from "../../features/commerce/commerceThunkApi";
import { useModal } from '../../hooks/useModal'
import { Button } from "../Button";
import { setSingleProductInfo } from "../../features/commerce/commerceSlice";

const OrderProductTable = ({ products, isAuthenticated, setOpenModal, handleDosesChange, orderPageContent }) =>{
    const [productObj, setProductObj] = useState({ productId: '', doseQty: 0 });
    const dispatch = useDispatch();
    const { handleOpenModal } = useModal();
    const isSpikevaxMresvia2024ProductEnabled = process.env.IS_SPIKEVAX_MRESVIA_2024_PRODUCTS_ENABLED === 'true';
    const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';
    const orderableProducts = useSelector((store) => store?.commerce?.productsData) || [];

    const TitleComponent = (value) => {
        const row = value.value.row;
        return (
            <div>
                <Box display="flex" alignItems="center" height={4} mb={2}>
                    <Box pl={2} style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'
                    }}>
                        <Typography style={{fontSize:'16px', fontWeight:'700', lineHeight:'28px'}}>
                            {row.productName}
                        </Typography>
                        <Button
                            sx={{padding: '0', textDecoration: 'none'}}
                            buttonType='link'
                            onClick={() => handleClickProductDetails(row.productId)}
                        >
                            <Typography sx={{textTransform: 'none'}} fontSize="14px" fontFamily="Aeonik Regular">
                                View product details
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </div>
        );
    };

    const handleClickProductDetails = (id) => {
        if(isCatalogServiceEnabled){
            const singleProductInfo = orderableProducts?.find(item => item?.materialMasterId === id);
            dispatch(setSingleProductInfo(singleProductInfo));
            setOpenModal(true);
        }
        else{
            dispatch(getProductDetailsApi({ productId: id })).unwrap()
                .then((data) => {
                    setOpenModal(true);
                })
        }
    }

    function CustomHeaderComponent() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={orderPageContent?.productImageRsvVaccine?.fields?.file?.url} alt="info"
                     style={{ marginRight: 10, width: 150}} />
                <Typography color={"#58595B"} textTransform={"none"} fontFamily={"Aeonik Regular"} fontWeight={600}>(Respiratory Syncytial Virus Vaccine)</Typography>
            </div>
        );
    }

    const columns = [
        {
            field: 'productName',
            headerName:'Product',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params) => (
                <TitleComponent value={params}/>
            ),
            ...(isSpikevaxMresvia2024ProductEnabled ? {} : { renderHeader: () => <CustomHeaderComponent /> })
        },
        {
            field: 'listPrice',
            headerName: 'List Price',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
        },
        {
            field: 'contractPrice',
            headerName: 'Contract Price',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            minWidth: 125
        },
        {
            field: 'doses',
            headerName: 'Doses',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            minWidth: 125,
            renderCell: (params) => {
                let val = params?.value
                if (productObj?.productId === params.id) {
                    val = productObj?.doseQty
                }
                return (
                    <TextField
                        type="number"
                        value={isAuthenticated ? val : 0}
                        onChange={(e) => updateTextVal(params.id, e.target.value)}
                        onBlur={(e) => handleDosesUpdate(params, e)}
                        onKeyDown={(e) => handleDosesUpdate(params, e)}
                        onClick={!isAuthenticated ? handleOpenModal : undefined}
                        inputProps={{min: 0}}
                        size="small"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                color: !isAuthenticated && 'text.disabled',
                                cursor: !isAuthenticated && 'default',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: !isAuthenticated && 'text.disabled',
                            },
                        }}
                    />)
            }
        },
    ];
    const filteredColumns = isAuthenticated ? columns : columns.filter(column => column.field === 'productName' || column.field === 'doses');

    const handleDosesUpdate = (product, event) => {
        const { id, value } = product
        const dose = Number(event.target.value);
        if (value === dose) return;
        if (event?.key === 'Enter' || event?.type === 'blur') {
            const doseQty = (Math.ceil(dose / 10)) * 10;
            setProductObj((prevState) =>({ ...prevState, productId: id, doseQty}));
            handleDosesChange(id, dose)
        }
    };

    const updateTextVal = (productId, doseQty) => {
        if (doseQty.charAt(0) === '0') {
            doseQty = doseQty.slice(1);
        }
        setProductObj((prevState) =>({...prevState, productId, doseQty: doseQty}));
    };

    return (
        <DataGrid
            autoHeight
            rowHeight={90}
            rows={products || []}
            columns={filteredColumns || []}
            hideFooter
            disableColumnFilter
            disableColumnMenu
        />
    );
}

export default OrderProductTable;
