import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { Provider } from 'react-redux';
// import store from './store';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import {theme} from "./assets/theme";
import { TrackingProvider } from '@modernatx/tracking';
import {PrivacyTextProvider } from '@modernatx/tracking/lib/PrivacyModal/usePrivacyText';
import {PrivacyModalConnected} from '@modernatx/tracking/lib/browser-entry';
const container = document.getElementById('root');
const root = createRoot(container);
const isAnonymizeTrackingEnabled = process.env.IS_ANONYMIZED_TRACKING_ENABLED === 'true';

root.render(
   <React.StrictMode>
       <TrackingProvider
           amplitudeApiKey={process.env.AMPLITUDE_DATA_KEY}
           customEventPrefix='Moderna Direct'
           defaultClickTracking={true}
          >
              <PrivacyTextProvider locale={'en-US'} readMoreURL={"/privacy-policy"}>
                  <PrivacyModalConnected anonymize={isAnonymizeTrackingEnabled}/>
              </PrivacyTextProvider>
        <ThemeProvider theme={theme}>

          <CookiesProvider>
            <BrowserRouter getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <App />
                </PersistGate>
              </Provider>
            </BrowserRouter>
          </CookiesProvider>

    </ThemeProvider>
       </TrackingProvider>
   </React.StrictMode>
);