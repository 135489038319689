"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isProductionDomain = void 0;
function isProductionDomain() {
    if (typeof window === 'undefined') {
        return false;
    }
    return [
        'app.livestorm.co',
        'atlas.modernatx.com',
        'bringyourbesttolife.com.sg',
        'coronataisaku-station.com',
        'caresportal.modernatx.com',
        'disease.education',
        'eua.modernatx.com',
        'event.leecy.org.tw',
        'gating.modernatx.com',
        'immunize.modernatx.com',
        'investors.modernatx.com',
        'moderna.qualtrics.com',
        'modernacovid19global.com',
        'modernadirect.com',
        'mrna-access.modernatx.com',
        'mrnabetterlife.com.hk',
        'news.modernatx.com',
        'nowiknowcmv.com',
        'pages.modernatx.com',
        'products.modernatx.com',
        'reimbursement.modernatx.com',
        'spikevax.com',
        'static.modernatx.com',
        'tafm-mrna.com.tw',
        'takecarecovid19moderna.jp',
        'taxform.modernatx.com',
        'tools.modernamedinfo.com',
        'tools.modernatx.com',
        'trials.modernatx.com',
        'vaccines.modernatx.com',
        'vaccines.modernatx.nl',
        'vacunas.modernatx.com',
        'www.bivalent-boostern.de',
        'www.covid19-evidenz.de',
        'www.makeityourvaccine.com',
        'www.modernatx.com',
        'www.spikevax-bivalent.de',
        'www.spikevax-evidenz.de',
        'www.takecarecovid19moderna-eppv.jp'
    ].includes(window.location.host);
}
exports.isProductionDomain = isProductionDomain;
