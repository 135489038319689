"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getIsBot = () => {
    const userAgent = window.navigator.userAgent;
    const agents = [
        'Applebot',
        'Bingbot',
        'Cypress',
        'Electron',
        'Googlebot',
        'HeadlessChrome',
        'PhantomJS',
        'Puppeteer',
        'RuxitSynthetic',
        'SemrushBot',
        'Tenable_WAS'
    ];
    return agents.some((agent) => userAgent.includes(agent));
};
exports.default = getIsBot;
