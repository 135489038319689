import { createAsyncThunk } from "@reduxjs/toolkit";
import authAxiosInstance from "../../sevices/authAxiosInstance";
import noAuthAxiosInstance from "../../sevices/noAuthAxiosInstance";

export const updateUserDetailsApi = async (payload) => {
    try {
        const res = await authAxiosInstance({
            url: `userservice/user/update-details`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const updateUserPreferencesApi = async (payload) => {
    try {
      const res = await authAxiosInstance({
        url: `userservice/user/update`,
        method: "POST",
        data: JSON.stringify(payload)
      });
      return res;
    } catch (error) {
        throw error;
    }
}


export const getUserByEmail = createAsyncThunk(
    "commerce/getUserByEmail",
    async ({ emailAddress } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `userservice/user/by-email/${emailAddress}`,
                method: "GET",
                timeout: 75000 //75 seconds
              });
            return res.data;
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.log("Request timed out", error);
            }
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const getUserById = createAsyncThunk(
    "commerce/getUserById",
    async ({ userId } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `userservice/user/${userId}`,
                method: "GET"
              });
            return res.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const getSAPUserByEmail = createAsyncThunk(
    "commerce/getSapUserByEmail",
    async ({ emailAddress } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `userservice/user/by-email/${emailAddress}/sap`,
                method: "GET",
                timeout: 75000 //75 seconds
              });
            return res;
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.log("Request timed out", error);
            }
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const getCustomerSegmentList = async () => {
    try {
        const res = await authAxiosInstance({
            url: `userservice/user/customer-segments`,
            method: "GET"
          });

        return res.data;
    } catch (error) {
        throw error;
    }
}

export const sendContactUsEmail = async (values) => {
    const reqBody = {
        contactMethod: values?.contactMethod,
        organization: values?.organization,
        privacyPolicyConsented: values?.consented,
        supportCategory: values?.supportCategory,
        userEmailAddress: values?.email,
        userFullName: values?.fullname,
        userPhone: values?.phone,
        captcha: {
            userCaptchaResponse: values?.recaptchaToken
        }
    }
    try {
      const res = await noAuthAxiosInstance({
        url: `userservice/contact`,
        method: "POST",
        data: reqBody
      });
      return res;
    } catch (error) {
        throw error;
    }
}
